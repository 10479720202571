import React from 'react'
import herramientas from '../../Assets/herramientas.webp'
import Decofrontal from '../../Assets/Decofrontal.webp'
import Tienda from '../../Assets/Tienda.webp'

function CardCarousel({
    recarga,
    deco,
    servicio,
    colors
}) {
    return (
        <div style={{ borderColor: colors?.bgColorSecondary ? colors?.bgColorSecondary : '#140D57' }} className='flex flex-col justify-between items-center text-center gap-10 border-2 p-5 h-[430px] md:h-[500px]  rounded-3xl  '>
            {
                recarga &&
                <img src={"https://s3.sa-east-1.amazonaws.com/imagenes.simple/SimpleTV/Assets/Tienda.webp"} alt='Tienda' className='w-[200px] ' />
            }
            {
                deco &&
                <img src={"https://s3.sa-east-1.amazonaws.com/imagenes.simple/SimpleTV/Assets/Decofrontal.webp"} alt='Decofrontal' className='w-[340px]' />
            }
            {
                servicio &&
                <img src={"https://s3.sa-east-1.amazonaws.com/imagenes.simple/SimpleTV/Assets/herramientas.webp"} alt='Herramientas' className='w-[200px] ' />
            }
            <div className='flex flex-col items-center gap-5'>


                {
                    servicio &&
                    <h3 style={{ color: colors?.textColorSecondary ? colors?.textColorSecondary : '#140D57' }} className='md:text-3xl text-xl font-bold '>Servicio Tecnico</h3>
                }
                {
                    deco &&
                    <h3 style={{ color: colors?.textColorSecondary ? colors?.textColorSecondary : '#140D57' }} className='md:text-3xl text-xl font-bold '>Ventas Decodificadores</h3>
                }
                {
                    recarga &&
                    <h3 style={{ color: colors?.textColorSecondary ? colors?.textColorSecondary : '#140D57' }} className='md:text-3xl text-xl font-bold'>Punto de Recarga</h3>
                }
                {
                    servicio &&
                    <p className='md:text-base text-sm w-[70%]   font-normal'>Ofrecemos soluciones rápidas y efectivas para resolver cualquier problema. Confía en nuestros expertos para mantener tu experiencia de entretenimiento sin problemas.</p>
                }
                {
                    deco &&
                    <p className='md:text-base text-sm w-[70%]   font-normal'>Descubre nuestra amplia gama de decodificadores. Mejora tu experiencia televisiva con las mejores opciones que tenemos para ti.</p>
                }
                {
                    recarga &&
                    <p className='md:text-base text-sm w-[70%]   font-normal'>Encuentra puntos de recarga convenientes para tu decodificador de TV en todo el país. Mantén tu entretenimiento sin interrupciones.</p>
                }
            </div>



        </div>
    )
}

export default CardCarousel