import React, { lazy, Suspense, useMemo } from 'react';

const LazyAfiliados = lazy(() => import('../../Utils/HomeAfiliados'));
const Logo = React.memo(() => {
    return <img src={"https://s3.sa-east-1.amazonaws.com/imagenes.simple/SimpleTV/Assets/LogoSimpleTv.webp"} alt='Logo' className='w-[162px] md:w-[263px] object-cover' />;
});

const HomeScreen = () => {
    const paragraphContent = useMemo(() => (
        <p className='md:text-xl text-center md:w-80 w-60 h-[72px]'>
            y obtén <span className='text-[#0072CE] md:text-xl font-bold'>7 días de prueba gratis </span>
            para disfrutar tus programas favoritos.
        </p>
    ), []);

    return (
        <div className='max-w-[1000px] 2xl:max-w-[1200px] mx-auto w-full flex flex-col gap-5 justify-center items-center h-min py-20 px-5'>
            <h2 className='md:text-5xl text-2xl text-[#140D57] font-bold text-center'>
                Ahora tu Deco Directv usalo en
            </h2>
            <Logo />
            {paragraphContent}

            <Suspense fallback={<div>Loading...</div>}>
                <LazyAfiliados hiddenButton />
            </Suspense>
        </div>
    );
};

export default HomeScreen;