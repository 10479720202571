import React from 'react'
import HomeAfiliados from '../../Utils/HomeAfiliados'
import DecodificarMovil from '../../../Assets/Bannerweb_2.webp'
import HomeAfiliadosDecoScreen from '../../Utils/HomeAfiliadosDecoScreen';

function DecoScreen() {

    const afiliadoRef = React.useRef(null);

    const scrollToAfiliado = () => {
        afiliadoRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='max-w-[1200px] 2xl:max-w-[1300px] mx-auto w-full flex flex-col gap-5 justify-center items-center h-min py-10 px-5'>
            <div className='flex md:flex-row flex-col gap-10'>
                <div className='flex flex-col gap-10' >
                    {/* <img src={DecodificarMovil} alt='Decodificador' className='rounded-2xl' /> */}
                    <img src={"https://s3.sa-east-1.amazonaws.com/imagenes.simple/SimpleTV/Assets/DecodificadorMobile.webp"} alt='Decodificador' className='rounded-2xl' />
                </div>
                <div className='flex flex-col gap-5  '>
                    <p className='md:text-5xl text-4xl text-left font-normal'>Adquiere tu Deco <span className='font-bold'>D LH01</span> fácilmente</p>
                    <p className='text-left text-base md:text-xl'>
                        Encuéntralo en el punto de venta más cercano y comienza a disfrutar de una <span className='font-bold'> experiencia televisiva superior </span>
                    </p>
                    <button onClick={() => scrollToAfiliado()} className='bg-[#8de365] text-white rounded-2xl p-5 py-2 text-base md:text-lg self-start hover:scale-110 transition-all'>
                        Ver puntos de venta
                    </button>
                </div>
            </div>
            <div className='grid gap-5 lg:grid-cols-5 grid-cols-2  mt-5'>
                <div className='border-2 rounded-3xl px-5 py-2 text-center flex items-center cursor-default border-[#36b8ea] text-sm md:text-base justify-center hover:scale-110 hover:bg-[#36b8ea] hover:text-white transition-all duration-150 ease-in-out'>
                    Alta definición y sonido envolvente.
                </div>
                <div className='border-2 rounded-3xl px-5 py-2 text-center flex items-center cursor-default border-[#36b8ea] text-sm md:text-base justify-center hover:scale-110 hover:bg-[#36b8ea] hover:text-white transition-all duration-150 ease-in-out'>
                    Canales SD y HD.
                </div>
                <div className='border-2 rounded-3xl px-5 py-2 text-center flex items-center cursor-default border-[#36b8ea] text-sm md:text-base justify-center hover:scale-110 hover:bg-[#36b8ea] hover:text-white transition-all duration-150 ease-in-out'>
                    Realizar búsquedas
                </div>
                <div className='border-2 rounded-3xl px-5 py-2 text-center flex items-center cursor-default border-[#36b8ea] text-sm md:text-base justify-center hover:scale-110 hover:bg-[#36b8ea] hover:text-white transition-all duration-150 ease-in-out'>
                    Autosintonizar programación
                </div>
                <div className='border-2 rounded-3xl px-5 py-2 text-center flex items-center cursor-default border-[#36b8ea] text-sm md:text-base justify-center hover:scale-110 hover:bg-[#36b8ea] hover:text-white transition-all duration-150 ease-in-out'>
                    Personalizar tu experiencia
                </div>
            </div>

            <div ref={afiliadoRef} className='w-full'>
                <HomeAfiliadosDecoScreen />
            </div>

        </div>
    )
}

export default DecoScreen